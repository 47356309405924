<template>
  <HeroOverviewSection
    :id="item.fields.id"
    :title="headline"
    :subtitle="subtitle"
    :text="text"
    :buttons="buttons"
    :items="items"
    :color-mode="colorMode"
    :content-centered="item.fields.contentPosition === 'centered'"
  >
  </HeroOverviewSection>
</template>

<script setup lang="ts">
import { HeroOverviewSection } from '@hypercodestudio/basler-components';
import type { HeadlineProps } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroProductSection.vue';
import type {
  OverviewItem,
  ColorMode
} from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroOverviewSection.vue';
import { getLinkIconPosition } from '~/utils/getLinkIconPosition';
import { generateImageObject } from '~/utils/generateImageObject';
import type { IHeroOverviewSection } from '~/lib/ContentfulService';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';

interface Props {
  item: IHeroOverviewSection;
  colorMode: ColorMode;
}
const props = defineProps<Props>();

// Die HeadlineProps beinhalten aktuell nur einen content. Hypercode wurde in Ticket #1318 darüber informiert.
const headline = computed<HeadlineProps>(() => ({
  content: props.item.fields.headline,
  badgeText: props.item.fields.badge?.fields.title
}));

const subtitle = computed<HeadlineProps | undefined>(() => {
  if (props.item.fields.subtitle) {
    return {
      content: props.item.fields.subtitle
    };
  }
});

// Der Link Type beinhaltet aktuell nur einen Text. Hypercode wurde in Ticket #1260 darüber informiert.
const buttons = computed(() =>
  props.item.fields.links
    ?.filter((entry) => !isEntryResolveError(entry))
    ?.map((field) => ({
      text: field.fields.linkText,
      link: useBuildLinkInterface(field),
      size: field.fields.linkStyleSize,
      styleType: field.fields.linkStyle ?? 'default',
      iconName: field.fields.linkIcon,
      targetBlank: field.fields.linkTarget === '_blank',
      iconType: getLinkIconPosition(field.fields.linkIconPosition)
    }))
);

const text = computed(() => {
  if (props.item.fields.description) {
    return { lead: false, content: props.item.fields.description };
  }
});

const items = computed<OverviewItem[]>(
  () =>
    props.item.fields.items
      ?.filter((entry) => !isEntryResolveError(entry))
      ?.map((item) => {
        const itemLink = useBuildLinkInterface(
          item.fields.externalLink ?? item.fields.internalPage
        )?.uri;
        const media = generateImageObject(item.fields.image, {
          sizes: '200px'
        });
        return {
          media,
          targetBlank: item.fields.linkTarget === '_blank',
          title: item.fields.linkText,
          link: itemLink
        };
      }) ?? []
);
</script>
